<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("clients.moreThanOne") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla
      </v-btn>
    </v-card-title>
    <v-card-text v-if="graphic">
      <v-row>
        <v-col cols="8">
          <BarChartClientsMoreThanOneService />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      v-else
      :headers="headersClientsMoreThanOneService"
      :items="list"
      :items-per-page="5"
      id="clientsMoreThanOne-table"
    >
      <template v-slot:item.first_name="{ item }">
        {{ item.user.first_name }}
      </template>
      <template v-slot:item.numServices="{ item }">
        {{ item.numServices }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ClientsMoreThanOneService",
  components: {
    BarChartClientsMoreThanOneService: () =>
      import(
        "@/components/stats/clients/graphics/ClientsMoreThanOneServiceBar"
      ),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.moreThanOneServiceClients;
    },
  },
  data() {
    return {
      graphic: true,
      headersClientsMoreThanOneService: [
        {
          text: this.$t("name"),
          align: "center",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: this.$t("Num Servicios"),
          align: "center",
          sortable: false,
          value: "numServices",
        },
      ],
    };
  },
};
</script>
